import React, { Component, useState } from "react";
import {
  Container,
  Card,
  CardBody,
  UncontrolledAlert,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Media,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  Modal,
  Alert,
  Label,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MiniWidgets from "../Dashboard/MiniWidgets";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
//Import Product Images
import img1 from "../../assets/images/product/img-1.png";
import img5 from "../../assets/images/product/img-5.png";
import img3 from "../../assets/images/product/img-3.png";
import img4 from "../../assets/images/product/img-4.png";
import lev from "../../assets/images/product/Picture2.jpg";
import ac06 from "../../assets/images/product/Picture1.jpg";
import ac06m from "../../assets/images/product/cfac06m.png";

import io from "socket.io-client";
import { connect } from "react-redux";
import { chargerSelected } from "../../store/chargers/actions";
import { linkify_fault_log } from "../../helpers/wiki_linker";
import { isTechnician } from "../Authentication/userMetaData";
import {
  getRequestTopic,
  getResponseTopic,
} from "../../helpers/topic_Management";
import { lkprimary } from "../Dashboard/styles";

let socket;
let location = {
  name: "",
  lat: "12.9843873",
  long: "77.7420796",
  address: "",
};

let chargerId_1 = "08ED8F1B5AE0";
//let chargerId_2 = "B401991B5AE0";
//let chargerId_3 = "944A4CEF49C0";
let chargerId_4 = "80C606144B8C";

/* let chargerId_1 = "6076A1ACCD98";
let chargerId_2 = "F8474CEF49C0";
let chargerId_3 = "20898795AB34"; */

//let chargerId_1 = "1CB705144B8C";
// //let chargerId_2 = "2C484CEF49C0";
let chargerId_2 = "D8478F0C8598";
//let chargerId_3 = "3C1E06144B8C";
let chargerId_3 = "98D88F1B5AE0";

let firmwareUpgradeURL = "";

//const rating = "7.2";

const expandRow = {
  renderer: (row) => (
    <>
      Action :
      <Link to="#" className="mr-3 text-primary">
        <i className="mdi mdi-pencil font-size-18"></i>
      </Link>
      <Link to="#" className="text-danger">
        <i className="mdi mdi-trash-can font-size-18"></i>
      </Link>
    </>
  ),

  showExpandColumn: true,
  expandByColumnOnly: true,
};

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Charger Management System", link: "#" },
        { title: "Charger Details", link: "#" },
      ],
      activeTab: "1",
      activeTab2: "1",
      chargerStatus: "Offline",
      start_btn_clr: lkprimary,
      stop_btn_clr: "#EFF2F7",
      start_btn_txt_clr: "#ffffff",
      stop_btn_txt_clr: "#000000",
      chargerId: props.selectedChargerId,
      transLog: [],
      faultLog: [],
      consumption: "Syncing...",
      ActiveTransactionId: 0,
      ocppId: "",
      syncIntervalId: "",
      imgSrc: lev,
      rating: "7.4",
      lasthb: "",
      perUnitRate: 0,
      currency: "₹",
      protocol: "Ocpp1.6",
      connectors: "",
      firmwareVersion: "",
      showAlert: false,
      firmwareUrl: "",
      alertMessage: "",

      user: props.user,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.toggleTab2 = this.toggleTab2.bind(this);
    this.imageShow = this.imageShow.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggleTab2(tab) {
    if (this.state.activeTab2 !== tab) {
      this.setState({
        activeTab2: tab,
      });
    }
  }

  toggleChargeButtons = (chargerStatus) => {
    if (chargerStatus == null) return;

    if (chargerStatus === "Charging") {
      this.setState({
        start_btn_clr: "#EFF2F7",
        stop_btn_clr: "#FF3D60",
        start_btn_txt_clr: "#000000",
        stop_btn_txt_clr: "#ffffff",
      });
    } else {
      this.setState({
        start_btn_clr: "#5664D2",
        stop_btn_clr: "#EFF2F7",
        start_btn_txt_clr: "#ffffff",
        stop_btn_txt_clr: "#000000",
      });
    }
  };

  imageShow(img, id) {
    var expandImg = document.getElementById("expandedImg" + id);
    expandImg.src = lev;
  }

  updateChargerImage = () => {
    let src = ac06;

    if (this.state.chargerId === null) src = ac06;

    if (this.state.chargerId === chargerId_1) src = ac06;
    //src = lev;

    if (this.state.chargerId === chargerId_2) src = lev;

    if (this.state.chargerId === chargerId_3) src = ac06;
    //src = lev;

    this.setState({ imgSrc: src });
  };

  updateChargerContent = () => {
    let rating = "7.4kW";
    let protocol = "OCPP1.6";
    let ocppId = "";
    if (this.state.chargerId === null) rating = null;

    if (this.state.chargerId === chargerId_1) rating = "7.4kW";
    //src = lev;

    if (this.state.chargerId === chargerId_2) {
      rating = "3.3kW";
      protocol = "BLE (LevAC)";
      ocppId = "NA";
    }

    if (this.state.chargerId === chargerId_3) rating = "22kW";
    if (this.state.chargerId === chargerId_4) rating = "7.4kW";

    this.setState({ rating, protocol, ocppId });
  };

  renderStatus = () => {
    if (this.state.chargerStatus === "Charging") {
      //return "mr-2 text-success";

      return "p-2 mr-2 text-white bg-success rounded";
    }
    if (this.state.chargerStatus === "Available") return "mr-2 text-success";
    //return "p-1 mr-2 text-white bg-primary rounded";

    if (this.state.chargerStatus === "Preparing") return "mr-2 text-warning";
    //return "p-1 mr-2 text-white bg-warning rounded";
    if (this.state.chargerStatus === "Faulted")
      // return "mr-2 text-danger";
      return "p-2 mr-2 text-white bg-danger rounded";
    if (this.state.chargerStatus === "Finishing") return "mr-2 text-warning";

    if (this.state.chargerStatus === "Offline") return "mr-2 text-danger";
    //return "p-1 mr-2 text-white bg-warning rounded";

    //return "mr-2 text-info";
    return "p-1 mr-2 text-white bg-info rounded";
  };

  socketSend = (topic, payload) => {
    if (topic == null) return;

    if (payload == null) return;

    if (topic.toString().length <= 0 || payload.toString().length <= 0) return;

    if (socket == null) return;

    if (!socket.active) return;

    socket.emit("publish", JSON.stringify({ topic, payload }));
  };

  msgSubscribe = (topic) => {
    if (socket == null) return;

    if (!socket.active) return;

    socket.emit("subscribe", { topic: getResponseTopic(this.state.chargerId) });
  };

  /* getResponseTopic = (chargerId) => {
        if (chargerId == null)
            return;

        return "/cms/" + chargerId + "/response";
    }

    getRequestTopic = (chargerId) => {
        if (chargerId == null)
            return;

        return "/cms/" + chargerId;
    } */

  processMsg = (data) => {
    try {
      if (data == null) return;

      if (data.type == null) return;

      switch (data.type) {
        case "startResponse": {
          if (data.transactionId)
            this.setState({ ActiveTransactionId: data.transactionId });
          break;
        }

        case "stopResponse": {
          if (data.transactionId == this.state.ActiveTransactionId)
            this.setState({ ActiveTransactionId: 0 });
          break;
        }

        case "statusLog": {
          if (!data.status) return;

          if (data.status === this.state.chargerStatus) return;

          this.toggleChargeButtons(data.status);
          this.setState({ chargerStatus: data.status });

          break;
        }

        case "meterValue": {
          if (data.energy) this.setState({ consumption: data.energy / 1000 });

          break;
        }

        case "firmwareUpdateResponse": {
          if (data.status) {
            console.log("firnwareUpdate Response", data.status);
            this.setState({
              alertMessage: data.status,
              showAlert: true,
            });
          }

          break;
        }

        default:
          break;
      }
    } catch (e) {
      console.log("Charger Details Process Message Error", e.toString());
    }
  };

  async componentDidMount() {
    /* if (this.props.selectedChargerId != null) {
            if (this.state.chargerId !== this.props.selectedChargerId) {
                this.setState({ chargerId: this.props.selectedChargerId, transLog: [] });


                console.log("Update ChargerId", this.state.chargerId);
            }
        } */

    try {
      await this.updateChargerDetails(this.state.chargerId);

      const charger_status = await this.updateChargerRecentStatus(
        this.state.chargerId
      );

      //const latest_transaction_id = await this.getLatestTransactionId(this.state.chargerId);
      //console.log("Latest Transaction Id", latest_transaction_id);

      if (charger_status != null) {
        if (charger_status !== this.state.chargerStatus) {
          const latest_transaction_id = await this.getLatestTransactionId(
            this.state.chargerId
          );
          this.toggleChargeButtons(charger_status);

          this.setState({
            chargerStatus: charger_status,
            ActiveTransactionId: latest_transaction_id,
          });
        }

        const latest_transaction_id = await this.getLatestTransactionId(
          this.state.chargerId
        );
      }

      await this.updateChargerTransLog(this.state.chargerId);
      await this.updateChargerFaultLog(this.state.chargerId);

      isTechnician(this.state.user)
        ? this.toggleTab2("2")
        : this.toggleTab2("1");
    } catch (e) {
      console.log("Charger Details Update Error", e.toString());
    }

    const intervalId = setInterval(async () => {
      try {
        //console.log("Charger Update Routine");
        await this.updateChargerTransLog(this.state.chargerId);
        await this.updateChargerFaultLog(this.state.chargerId);
      } catch (e) {
        console.log("Charger Details Update Error", e.toString());
      }
    }, 5000);

    this.setState({ syncIntervalId: intervalId });

    socket = io("https://backend.iqlabs.io:3000");

    socket.on("connect", () => {
      console.log("Socket Connection Successful");

      //console.log("Response Topic", this.getResponseTopic(this.state.chargerId))

      socket.emit(
        "subscribe",
        JSON.stringify({ topic: getResponseTopic(this.state.chargerId) })
      );

      socket.on("mqtt", (msg) => {
        if (msg == null) return;

        //console.log("Rx Msg", msg);
        const msgJson = JSON.parse(msg);
        const topic = msgJson.topic;
        const payload = JSON.parse(msgJson.payload);
        console.log("Rx Msg Payload", payload);
        this.processMsg(payload);
      });
    });

    this.updateChargerImage();
    this.updateChargerContent();
  }

  async componentWillUnmount() {
    //console.log("Charger Page Exited");

    clearInterval(this.state.syncIntervalId);

    if (socket) {
      // socket.emit(
      //   "unsubscribe",
      //   JSON.stringify({ topic: getResponseTopic(this.state.chargerId) })
      // );

      socket.disconnect();
    }

    this.setState({
      transLog: [],
      consumption: "Syncing...",
      ActiveTransactionId: 0,
    });
  }

  computeTime = (isodate) => {
    if (isodate == null) return "";

    const date = new Date(isodate);

    let hour = date.getHours().toString();
    let mins = date.getMinutes().toString();
    let secs = date.getSeconds().toString();

    if (hour.length < 2) hour = "0" + hour;

    if (mins.length < 2) mins = "0" + mins;

    if (secs.length < 2) secs = "0" + secs;

    return hour + ":" + mins + ":" + secs;
  };

  computeDate = (isodate) => {
    if (isodate == null) return "";

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(isodate);
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear().toString();

    if (day < 10) day = "0" + day.toString();

    if (month < 10) month = "0" + month.toString();

    return day + " " + months.at(month) + ", " + year;
  };

  computeConsumption = (starMeterVal, stopMeterVal) => {
    if (isNaN(starMeterVal)) return "";

    if (isNaN(stopMeterVal))
      return (
        <div className="badge badge-soft-warning font-size-12">On-going</div>
      );

    return (
      (parseInt(stopMeterVal, 10) - parseInt(starMeterVal, 10)) /
      1000
    ).toString();
  };

  computeTotal = (starMeterVal, stopMeterVal) => {
    if (isNaN(stopMeterVal))
      return (
        <div className="badge badge-soft-warning font-size-12">On-going</div>
      );

    return (
      "\u20B9" +
      (
        ((parseInt(stopMeterVal, 10) - parseInt(starMeterVal, 10)) * 10) /
        1000
      ).toString()
    );
  };

  computePaymentStatus = (starMeterVal, stopMeterVal) => {
    if (isNaN(stopMeterVal))
      return <div className="badge badge-soft-warning font-size-12">Paid</div>;

    return <div className="badge badge-soft-success font-size-12">Paid</div>;
  };

  updateChargerRecentStatus = async (chargerId) => {
    if (chargerId == null) return;

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      /*    body: JSON.stringify({
                   token: "8lQzN922rpRDaMbh3m5J",
                   id: "#"
               }) */
    };
    const response = await fetch(
      "https://backend.iqlabs.io/api/evChargers/statusLogs/recent?token=8lQzN922rpRDaMbh3m5J&id=" +
        chargerId,
      requestOptions
    );
    const responseJson = await response.json();
    //console.log("Fetch data Reponse", responseJson);
    const data = responseJson.data;
    if (data.length <= 0) return;

    //console.log("Fetch data Reponse", data.at(0));
    return data.at(0).status;
  };

  getLatestTransactionId = async (chargerId) => {
    if (chargerId == null) return;

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      /*    body: JSON.stringify({
                   token: "8lQzN922rpRDaMbh3m5J",
                   id: "#"
               }) */
    };
    const response = await fetch(
      "https://backend.iqlabs.io/api/evChargers/transactions/recent?token=8lQzN922rpRDaMbh3m5J&id=" +
        chargerId,
      requestOptions
    );
    const responseJson = await response.json();
    //console.log("Fetch data Reponse", responseJson);
    const data = responseJson.data;
    if (data.length <= 0) return;

    //console.log("Fetch Latest Transaction Id", data.at(0));
    return data.at(0).transactionId;
  };

  updateChargerDetails = async (chargerId) => {
    if (chargerId == null) return;

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      /*    body: JSON.stringify({
                   token: "8lQzN922rpRDaMbh3m5J",
                   id: "#"
               }) */
    };
    const response = await fetch(
      "https://backend.iqlabs.io/api/evChargers/chargers/?token=8lQzN922rpRDaMbh3m5J&id=" +
        chargerId,
      requestOptions
    );
    const responseJson = await response.json();

    //console.log("Fetch Charger Details", responseJson.data[0]);
    if (responseJson.data[0] == null) return;

    const data = responseJson.data[0];

    //console.log("Fetch Charger Details", data);
    let updatedChgVals = {};

    const rating = chargerId === chargerId_2 ? "3.3kW" : data.rating;
    const lasthb = data.lastHeartbeat;
    const currency = data.currency;
    const perUnitRate = data.perUnitRate;
    const protocol = data.protocolType;
    const connectors = data.connectors;
    const firmwareVersion = data.firmwareVersion;
    let ocppId = "";

    if (data.ocppConnections[0].ocppId) ocppId = data.ocppConnections[0].ocppId;

    this.setState({
      rating: rating,
      lasthb: this.computeDate(lasthb) + " " + this.computeTime(lasthb),
      currency: this.getCurrencySymbol(currency),
      perUnitRate: perUnitRate,
      protocol: protocol,
      connectors: this.getConnectorDetails(connectors),
      firmwareVersion: firmwareVersion,
      ocppId: ocppId,
    });
  };

  getConnectorDetails = (connectors) => {
    if (connectors == null) return "";

    if (connectors.length <= 0) return "";

    let connDetails = "";

    if (connectors.length == 1) connDetails = "Single-gun ";
    else if (connectors.length == 2) connDetails = "Dual-gun ";
    else connDetails = "Muli-gun ";

    connectors.forEach((conn) => {
      connDetails += conn.connectorType + ";";
    });

    return connDetails;
  };

  getCurrencySymbol = (currency) => {
    if (currency == null) return "";

    switch (currency.toLowerCase()) {
      case "rupee":
        return "₹";

      case "usd":
        return "$";

      case "pound":
        return "£";

      default:
        return currency;
    }
  };

  updateChargerTransLog = async (chargerId) => {
    //console.log("Update Charger Id", chargerId);

    if (chargerId == null) return;

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      /*    body: JSON.stringify({
                   token: "8lQzN922rpRDaMbh3m5J",
                   id: "#"
               }) */
    };
    const response = await fetch(
      "https://backend.iqlabs.io/api/evChargers/transactions?token=8lQzN922rpRDaMbh3m5J&id=" +
        chargerId,
      requestOptions
    );
    const responseJson = await response.json();
    //console.log("Fetch Transaction data Reponse", responseJson);
    const data = responseJson.data;

    //console.log("Data Fetched");
    let i = 0,
      trans_data = [];
    data.forEach((item) => {
      trans_data.push({
        connId: item.connectorId,
        chargerId: (
          <Link to="cms-charger-detail" className="text-dark font-weight-bold">
            {item.chargerId}
          </Link>
        ),
        date:
          this.computeDate(item.startTime) +
          ", " +
          this.computeTime(item.startTime),
        user: item.userId,
        transactionId: item.transactionId,
        total: this.computeTotal(
          parseInt(item.meterStart, 10),
          parseInt(item.meterStop, 10)
        ),
        units: this.computeConsumption(
          parseInt(item.meterStart, 10),
          parseInt(item.meterStop, 10)
        ),
        status: this.computePaymentStatus(
          parseInt(item.meterStart, 10),
          parseInt(item.meterStop, 10)
        ),
      });
    });

    if (JSON.stringify(trans_data) === JSON.stringify(this.state.transLog))
      return;

    this.setState({ transLog: trans_data });
  };

  updateChargerFaultLog = async (chargerId) => {
    //console.log("Update Charger Id", chargerId);

    if (chargerId == null) return;

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      /*    body: JSON.stringify({
                   token: "8lQzN922rpRDaMbh3m5J",
                   id: "#"
               }) */
    };
    const response = await fetch(
      "https://backend.iqlabs.io/api/evChargers/statusLogs?token=8lQzN922rpRDaMbh3m5J&type=Faulted&id=" +
        chargerId,
      requestOptions
    );
    const responseJson = await response.json();
    //console.log("Fetch data Reponse", responseJson);
    const data = responseJson.data;

    data.sort((a, b) => -a.timeStamp.localeCompare(b.timeStamp));

    let fault_data = [];
    data.forEach((item) => {
      fault_data.push({
        connId: item.connectorId,
        chargerId: item.chargerId,
        timeStamp:
          this.computeDate(item.timeStamp) +
          " " +
          this.computeTime(item.timeStamp),
        details: this.linkifyFaultLog(item.details),
      });
    });

    if (JSON.stringify(fault_data) === JSON.stringify(this.state.faultLog))
      return;

    //console.log("Fault Data Log", data);

    this.setState({ faultLog: fault_data });
  };

  firmwareInputChange = (event) => {
    if (!event) return;

    if (!event.target.value) return;

    firmwareUpgradeURL = event.target.value;
  };

  linkifyFaultLog = (faultDetail) => {
    if (!faultDetail) return;

    if (faultDetail.indexOf("ESBPressed") !== -1)
      return (
        <Link
          to={{ pathname: "http://localhost:8800/doku.php?id=esbpressed" }}
          target="_blank"
          className="text-primary font-weight-bold"
          style={{ color: "	#0000FF" }}
        >
          {faultDetail}
        </Link>
      );

    return faultDetail;
  };

  renderAlertMessage = (msg) => {
    if (!msg) return;

    switch (msg) {
      case "Installed": {
        return (
          <UncontrolledAlert color="success">
            Installation Successful!
          </UncontrolledAlert>
        );
      }

      case "Downloaded": {
        return (
          <UncontrolledAlert color="primary">Download done!</UncontrolledAlert>
        );
      }

      case "DownloadFailed": {
        return (
          <UncontrolledAlert color="danger">Download failed!</UncontrolledAlert>
        );
      }

      case "Downloading": {
        return (
          <UncontrolledAlert color="warning">Downloading...</UncontrolledAlert>
        );
      }

      case "InstallationFailed": {
        return (
          <UncontrolledAlert color="danger">
            Installation Failed
          </UncontrolledAlert>
        );
      }

      case "Installing": {
        return (
          <UncontrolledAlert color="primary">Installing...</UncontrolledAlert>
        );
      }

      default:
        break;
    }
  };

  firmware_upgrade_click = () => {
    if (!firmwareUpgradeURL) return;

    if (firmwareUpgradeURL.length == 0) return;

    this.socketSend(getRequestTopic(this.state.chargerId), {
      type: "firmwareUpdate",
      location: firmwareUpgradeURL,
    });
  };

  render() {
    const data = {
      columns: [
        {
          dataField: "transactionId",
          text: "Transaction Id",
        },

        {
          dataField: "chargerId",
          text: "Charger ID",
        },
        {
          dataField: "connId",
          text: "Conn",
        },
        {
          dataField: "date",
          text: "Date",
        },
        {
          dataField: "user",
          text: "User(RFID)",
        },
        {
          dataField: "units",
          text: "Units",
        },
        {
          dataField: "total",
          text: "Total",
        },

        {
          dataField: "status",
          text: "Payment Status",
        },
      ],
      rows: [
        {
          id: 1,
          orderId: (
            <Link to="#" className="text-dark font-weight-bold">
              #CF1572
            </Link>
          ),
          date: "04 Nov, 2022",
          billingName: "Bagmane Group",
          total: "₹ 1,72,000",
          status: (
            <div className="badge badge-soft-success font-size-12">Paid</div>
          ),
        },
        {
          id: 1,
          orderId: (
            <Link to="#" className="text-dark font-weight-bold">
              #CF1571
            </Link>
          ),
          date: "02 Nov, 2022",
          billingName: "IHCL",
          total: "₹ 1251,000",
          status: (
            <div className="badge badge-soft-warning font-size-12">unpaid</div>
          ),
        },
        {
          id: 1,
          orderId: (
            <Link to="#" className="text-dark font-weight-bold">
              #CF1570
            </Link>
          ),
          date: "01 Nov, 2022",
          billingName: "Blu Smart",
          total: "₹ 146,000,",
          status: (
            <div className="badge badge-soft-success font-size-12">Paid</div>
          ),
        },
        {
          id: 4,
          orderId: (
            <Link to="#" className="text-dark font-weight-bold">
              #CF1569
            </Link>
          ),
          date: "01 Nov, 2022",
          billingName: "Phoenix",
          total: "₹ 907,200",
          status: (
            <div className="badge badge-soft-success font-size-12">Paid</div>
          ),
        },
        {
          id: 1,
          orderId: (
            <Link to="#" className="text-dark font-weight-bold">
              #CF1568
            </Link>
          ),
          date: "01 Nov, 2022",
          billingName: "Lalit Group",
          total: "₹ 8100",
          status: (
            <div className="badge badge-soft-danger font-size-12">
              Chargeback
            </div>
          ),
        },
        {
          id: 1,
          orderId: (
            <Link to="#" className="text-dark font-weight-bold">
              #CF1567
            </Link>
          ),
          date: "03 Nov, 2021",
          billingName: "Anirudh Harish",
          total: "₹ 54",
          status: (
            <div className="badge badge-soft-warning font-size-12">unpaid</div>
          ),
        },
        {
          id: 1,
          orderId: (
            <Link to="#" className="text-dark font-weight-bold">
              #CF1566
            </Link>
          ),
          date: "03 Nov, 2021",
          billingName: "Walter Golmes",
          total: "₹ 252",
          status: (
            <div className="badge badge-soft-success font-size-12">Paid</div>
          ),
        },
        {
          id: 1,
          orderId: (
            <Link to="#" className="text-dark font-weight-bold">
              #NZ1565
            </Link>
          ),
          date: "03 Nov, 2021",
          billingName: "Abdul Aleim",
          total: "₹ 306",
          status: (
            <div className="badge badge-soft-success font-size-12">Paid</div>
          ),
        },
        {
          id: 1,
          orderId: (
            <Link to="#" className="text-dark font-weight-bold">
              #NZ1564
            </Link>
          ),
          date: "03 Nov, 2021",
          billingName: "Abinav Sankar",
          total: "₹ 117",
          status: (
            <div className="badge badge-soft-success font-size-12">Paid</div>
          ),
        },
        {
          id: 1,
          orderId: (
            <Link to="#" className="text-dark font-weight-bold">
              #NZ1563
            </Link>
          ),
          date: "03 Nov, 2021",
          billingName: "Aditya Thackeray",
          total: "₹ 27",
          status: (
            <div className="badge badge-soft-warning font-size-12">unpaid</div>
          ),
        },
        {
          id: 1,
          orderId: (
            <Link to="#" className="text-dark font-weight-bold">
              #NZ1562
            </Link>
          ),
          date: "03 Nov, 2021",
          billingName: "Sachin Ahir",
          total: "₹ 90",
          status: (
            <div className="badge badge-soft-success font-size-12">Paid</div>
          ),
        },
      ],
    };

    const fault_data = {
      columns: [
        {
          dataField: "connId",
          text: "Conn",
        },
        {
          dataField: "chargerId",
          text: "Charger ID",
        },
        {
          dataField: "timeStamp",
          text: "Date & Time",
        },
        {
          dataField: "details",
          text: "Details",
        },
      ],
    };

    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList: [
        {
          text: "5th",
          value: 5,
        },
        {
          text: "10th",
          value: 10,
        },
        {
          text: "All",
          value: this.state.transLog.length,
        },
      ],
    };

    const optionsFault = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList: [
        {
          text: "5th",
          value: 5,
        },
        {
          text: "10th",
          value: 10,
        },
        {
          text: "All",
          value: this.state.faultLog.length,
        },
      ],
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
    };

    const start_click = () => {
      if (this.state.chargerStatus === "Offline") {
        alert("Charger is Offline");
        return;
      }

      if (this.state.chargerStatus !== "Charging") {
        /* console.log("Socket Send Topic", this.getRequestTopic(this.state.chargerId));

                console.log("Socket Send Payload", JSON.stringify({
                    type: "remoteStart",
                    idTag: "9902062209",
                    connectorId: 1
                })); */

        this.socketSend(getRequestTopic(this.state.chargerId), {
          type: "remoteStart",
          idTag: "9989727888",
          connectorId: 1,
        });
      }
    };

    const stop_click = () => {
      //console.log("Stop Charge TransactionId", this.state.ActiveTransactionId);
      if (this.state.ActiveTransactionId === 0) {
        alert("Not Authorized to stop this transaction");
        return;
      }

      if (this.state.chargerStatus === "Offline") {
        alert("Charge is Offline");
        return;
      }
      console.log("Sending Stop");

      this.socketSend(getRequestTopic(this.state.chargerId), {
        type: "remoteStop",
        transactionId: this.state.ActiveTransactionId,
      });
    };

    const firmware_upgrade_click = () => {
      // if(firmwareUpgradeURL ==)
    };

    //let meter_value = 0.0;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Charger Details"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl={5}>
                        <div className="product-detail">
                          <Row>
                            <Col md={8} xs={9}>
                              <TabContent
                                activeTab={this.state.activeTab}
                                id="v-pills-tabContent"
                              >
                                <TabPane tabId="1">
                                  <div className="product-img">
                                    <img
                                      src={this.state.imgSrc}
                                      id="expandedImg1"
                                      style={{ height: 200 }}
                                      alt=""
                                      className="img-fluid mx-auto d-block"
                                    />
                                  </div>
                                </TabPane>
                                <TabPane tabId="2">
                                  <div className="product-img">
                                    <img
                                      src={this.state.imgSrc}
                                      id="expandedImg2"
                                      alt=""
                                      className="img-fluid mx-auto d-block"
                                    />
                                  </div>
                                </TabPane>
                                <TabPane tabId="3">
                                  <div className="product-img">
                                    <img
                                      src={this.state.imgSrc}
                                      id="expandedImg3"
                                      alt=""
                                      className="img-fluid mx-auto d-block"
                                    />
                                  </div>
                                </TabPane>
                                <TabPane tabId="4">
                                  <div className="product-img">
                                    <img
                                      src={this.state.imgSrc}
                                      id="expandedImg4"
                                      alt=""
                                      className="img-fluid mx-auto d-block"
                                    />
                                  </div>
                                </TabPane>
                              </TabContent>
                              {this.state.rating !== "3.3kW" && (
                                <Row className="text-center mt-2">
                                  <Col sm={6}>
                                    <Button
                                      onClick={start_click}
                                      style={{
                                        backgroundColor:
                                          this.state.start_btn_clr,
                                        color: this.state.start_btn_txt_clr,
                                      }}
                                      color="primary"
                                      block
                                      type="button"
                                      className="waves-effect waves-light mt-2 mr-1"
                                    >
                                      <i className="mdi mr-2"></i> Start Charge
                                    </Button>
                                  </Col>
                                  <Col sm={6}>
                                    <Button
                                      onClick={stop_click}
                                      style={{
                                        backgroundColor:
                                          this.state.stop_btn_clr,
                                        color: this.state.stop_btn_txt_clr,
                                      }}
                                      color="light"
                                      block
                                      type="button"
                                      className="btn-block waves-effect  mt-2 waves-light"
                                    >
                                      <i className="mdi mr-2"></i>Stop Charge
                                    </Button>
                                  </Col>
                                </Row>
                              )}
                              <br></br>
                              <h5 className="font-size-14 mb-3">
                                <b>
                                  {this.state.rating !== "3.3kW"
                                    ? "Ocpp Id:"
                                    : undefined}
                                </b>{" "}
                                {this.state.rating !== "3.3kW"
                                  ? this.state.ocppId
                                  : undefined}
                              </h5>
                              <h5 className="font-size-14 mb-3">
                                <b>Transaction Id:</b>{" "}
                                {this.state.ActiveTransactionId}
                              </h5>
                              <h5 className="font-size-14 mb-3">
                                <b>Consumption(in kWh):</b>{" "}
                                {this.state.consumption}
                              </h5>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col xl={7}>
                        <div className="mt-4 mt-xl-3">
                          <Link to="#" className="text-primary">
                            ID: {this.state.chargerId}
                          </Link>
                          <h5 className="mt-1 mb-3">
                            {this.state.rating} OCPP Charger
                          </h5>

                          {/* <div className="d-inline-flex">
                                                        <div className="text-muted mr-3">
                                                            <span className="mdi mdi-star text-warning mr-1"></span>
                                                            <span className="mdi mdi-star text-warning mr-1"></span>
                                                            <span className="mdi mdi-star text-warning mr-1"></span>
                                                            <span className="mdi mdi-star text-warning mr-1"></span>
                                                            <span className="mdi mdi-star mr-1"></span>
                                                        </div>
                                                        <div className="text-muted">( 132 )</div>
    </div>*/}

                          <h5 className="mt-2">
                            <span className={this.renderStatus()}>
                              {this.state.chargerStatus}
                            </span>{" "}
                            <span className="text-danger font-size-12 ml-2">
                              {" "}
                              {"<"} 5% Downtime
                            </span>
                          </h5>
                          {/*<p className="mt-3">To achieve this, it would be necessary to have uniform pronunciation</p>*/}
                          <hr className="my-4" />

                          <Row>
                            <Col md={6}>
                              <div>
                                {/* <h5 className="font-size-14"><i className="mdi mdi-location"></i> Delivery location</h5>
                                                                <div className="form-inline">

                                                                    <InputGroup className="mb-3">
                                                                        <Input type="text" className="form-control" placeholder="Enter Delivery pincode " />
                                                                        <InputGroupAddon addonType="append">
                                                                            <Button color="light" type="button">Check</Button>
                                                                        </InputGroupAddon>
                                                                    </InputGroup>
                                                                </div> */}

                                <h5 className="font-size-14">
                                  Params :{" "}
                                  {!isTechnician(this.state.user) && (
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        this.setState({
                                          modal_static: true,
                                          isAlertOpen: false,
                                        })
                                      }
                                    >
                                      <i className="mdi mdi-lead-pencil text-dark mr-1 align-middle font-size-14"></i>
                                    </Link>
                                  )}
                                </h5>
                                <ul className="list-unstyled product-desc-list">
                                  <li>
                                    <i className="mdi mdi-sync text-primary mr-1 align-middle font-size-16"></i>{" "}
                                    Last Service: 27 May 24
                                  </li>
                                  <li>
                                    <i className="mdi mdi-sync text-primary mr-1 align-middle font-size-16"></i>{" "}
                                    Last Heartbeat: <b>{this.state.lasthb}</b>
                                  </li>
                                  {!isTechnician(this.state.user) && (
                                    <li>
                                      <i className="mdi mdi-currency-usd-circle text-primary mr-1 align-middle font-size-20"></i>
                                      Unit Price:{" "}
                                      <b>
                                        {this.state.currency}{" "}
                                        {this.state.perUnitRate}
                                      </b>{" "}
                                      {"  "}
                                      <Link
                                        to="#"
                                        onClick={() =>
                                          this.setState({
                                            modal_static: true,
                                            isAlertOpen: false,
                                          })
                                        }
                                      >
                                        <i className="mdi mdi-lead-pencil text-dark mr-1 align-middle font-size-12"></i>
                                      </Link>
                                    </li>
                                  )}
                                  <li>
                                    <i className="mdi mdi-file-outline text-primary mr-1 align-middle font-size-16"></i>{" "}
                                    Firmware:{" "}
                                    <b>{this.state.firmwareVersion}</b>{" "}
                                  </li>
                                </ul>

                                <h5 className="font-size-14">
                                  <i className="mdi mdi-location"></i> Firmware
                                  Upgrade
                                </h5>
                                <div className="form-inline">
                                  <InputGroup className="mb-3">
                                    <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="URL "
                                      onChange={this.firmwareInputChange}
                                    />
                                    <InputGroupAddon addonType="append">
                                      <Button
                                        color="light"
                                        type="button"
                                        onClick={() =>
                                          this.firmware_upgrade_click()
                                        }
                                      >
                                        Upgrade
                                      </Button>
                                    </InputGroupAddon>
                                  </InputGroup>
                                  {this.state.showAlert &&
                                    this.renderAlertMessage(
                                      this.state.alertMessage
                                    )}
                                </div>
                              </div>
                            </Col>

                            <div>
                              <h5 className="font-size-14">Features :</h5>
                              <ul className="list-unstyled product-desc-list">
                                <li>
                                  <i className="mdi mdi-circle-medium mr-1 align-middle"></i>{" "}
                                  Power: AC {this.state.rating}
                                </li>
                                <li>
                                  <i className="mdi mdi-circle-medium mr-1 align-middle"></i>{" "}
                                  Protocol: {this.state.protocol}j
                                </li>
                                <li>
                                  <i className="mdi mdi-circle-medium mr-1 align-middle"></i>{" "}
                                  Connector: {this.state.connectors}
                                </li>
                                <li>
                                  <i className="mdi mdi-circle-medium mr-1 align-middle"></i>{" "}
                                  Battery Backup
                                </li>
                              </ul>
                            </div>
                          </Row>

                          <Row>
                            {/* <Col md={6}>
                                                            <div className="product-color mt-3">
                                                                <h5 className="font-size-14">Color :</h5>
                                                                <Link to="#" className="active">
                                                                    <div className="product-color-item">
                                                                        <img src={img1} alt="" className="avatar-md"/>
                                                                    </div>
                                                                    <p>Blue</p>
                                                                </Link>
                                                                <Link to="#" className="ml-1">
                                                                    <div className="product-color-item">
                                                                        <img src={img5} alt="" className="avatar-md"/>
                                                                    </div>
                                                                    <p>Cyan</p>
                                                                </Link>
                                                                <Link to="#" className="ml-1">
                                                                    <div className="product-color-item">
                                                                        <img src={img3} alt="" className="avatar-md"/>
                                                                    </div>
                                                                    <p>Green</p>
                                                                </Link>
                                                            </div>
                                                        </Col> 

                                                        <Col md={6}>
                                                            <div className="product-color mt-3">
                                                                <h5 className="font-size-14">Size :</h5>
                                                                <Link to="#" className="active ml-1">
                                                                    <div className="product-color-item">
                                                                        <div className="avatar-xs">
                                                                            <span className="avatar-title bg-transparent text-body">S</span>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </Link>
                                                                <Link to="#" className="ml-1">
                                                                    <div className="product-color-item">
                                                                        <div className="avatar-xs">
                                                                            <span className="avatar-title bg-transparent text-body">M</span>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                                <Link to="#" className="ml-1">
                                                                    <div className="product-color-item">
                                                                        <div className="avatar-xs">
                                                                            <span className="avatar-title bg-transparent text-body">L</span>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                                <Link to="#" className="ml-1">
                                                                    <div className="product-color-item">
                                                                        <div className="avatar-xs">
                                                                            <span className="avatar-title bg-transparent text-body">XL</span>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </Col>*/}
                          </Row>
                        </div>
                      </Col>
                    </Row>

                    <div className="mt-4">
                      {/* <h5 className="font-size-14 mb-3"><b>Consumption(in kWh): {this.state.consumption}</b></h5> */}
                      <h5 className="font-size-14 mb-3">Logs: </h5>
                      {
                        <div className="product-desc">
                          <Nav tabs className="nav-tabs-custom" role="tablist">
                            {!isTechnician(this.state.user) && (
                              <NavItem>
                                <NavLink
                                  id="desc-tab"
                                  className={classnames({
                                    active: this.state.activeTab2 === "1",
                                  })}
                                  onClick={() => {
                                    this.toggleTab2("1");
                                  }}
                                >
                                  Transactions
                                </NavLink>
                              </NavItem>
                            )}
                            <NavItem>
                              <NavLink
                                id="specifi-tab"
                                className={classnames({
                                  active: this.state.activeTab2 === "2",
                                })}
                                onClick={() => {
                                  this.toggleTab2("2");
                                }}
                              >
                                Faults
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent
                            activeTab={this.state.activeTab2}
                            className="border border-top-0 p-4"
                          >
                            {!isTechnician(this.state.user) && (
                              <TabPane tabId="1" role="tabpanel">
                                {/* <div>
                                                            <p>If several languages coalesce, the grammar of the resulting language is more simple and regular than that of the individual </p>
                                                            <p>To achieve this, it would be necessary to have uniform grammar, pronunciation and more common several languages coalesce, the grammar of the resulting.</p>
                                                            <p>It will be as simple as occidental in fact.</p>

                                                            <div>
                                                                <p className="mb-2"><i className="mdi mdi-circle-medium mr-1 align-middle"></i> If several languages coalesce</p>
                                                                <p className="mb-2"><i className="mdi mdi-circle-medium mr-1 align-middle"></i> To an English person, it will seem like simplified</p>
                                                                <p className="mb-0"><i className="mdi mdi-circle-medium mr-1 align-middle"></i> These cases are perfectly simple.</p>
                                                            </div>
                                                    </div> */}
                                <BootstrapTable
                                  keyField="id"
                                  data={this.state.transLog}
                                  columns={data.columns}
                                  expandRow={expandRow}
                                  pagination={paginationFactory(options)}
                                  selectRow={selectRow}
                                />
                              </TabPane>
                            )}
                            <TabPane tabId="2" id="specifi" role="tabpanel">
                              {/*<div className="table-responsive">
                                                            <Table className="table-nowrap mb-0">
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row" style={{ width: "400px" }}>Category</th>
                                                                        <td>T-Shirt</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Brand</th>
                                                                        <td>Jack & Jones</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Color</th>
                                                                        <td>Blue</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Material</th>
                                                                        <td>Cotton</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Weight</th>
                                                                        <td>140 G</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                </div> */}
                              <BootstrapTable
                                keyField="id"
                                data={this.state.faultLog}
                                columns={fault_data.columns}
                                expandRow={expandRow}
                                pagination={paginationFactory(optionsFault)}
                                selectRow={selectRow}
                              />
                            </TabPane>
                          </TabContent>
                        </div>
                      }
                    </div>

                    {/* <div className="mt-4">
                                            <h5 className="font-size-14">Reviews : </h5>
                                            <div className="d-inline-flex mb-3">
                                                <div className="text-muted mr-3">
                                                    <span className="mdi mdi-star text-warning mr-1"></span>
                                                    <span className="mdi mdi-star text-warning mr-1"></span>
                                                    <span className="mdi mdi-star text-warning mr-1"></span>
                                                    <span className="mdi mdi-star text-warning mr-1"></span>
                                                    <span className="mdi mdi-star mr-1"></span>
                                                </div>
                                                <div className="text-muted">( 132  customer Review)</div>
                                            </div>
                                            <div className="border p-4 rounded">
                                                <Media className="border-bottom pb-3">
                                                    <Media body>
                                                        <p className="text-muted mb-2">To an English person, it will seem like simplified English, as a skeptical Cambridge</p>
                                                        <h5 className="font-size-15 mb-3">James</h5>

                                                        <ul className="list-inline product-review-link mb-0">
                                                            <li className="list-inline-item">
                                                                <Link to="#"><i className="mdi mdi-thumb-up align-middle mr-1"></i> Like</Link>
                                                            </li>
                                                            <li className="list-inline-item ml-1">
                                                                <Link to="#"><i className="mdi mdi-message-text align-middle mr-1"></i> Comment</Link>
                                                            </li>
                                                        </ul>
                                                    </Media>
                                                    <p className="float-sm-right font-size-12">11 Feb, 2020</p>
                                                </Media>
                                                <Media className="border-bottom py-3">
                                                    <Media body>
                                                        <p className="text-muted mb-2">Everyone realizes why a new common language would be desirable</p>
                                                        <h5 className="font-size-15 mb-3">David</h5>

                                                        <ul className="list-inline product-review-link mb-0">
                                                            <li className="list-inline-item">
                                                                <Link to="#"><i className="mdi mdi-thumb-up align-middle mr-1"></i> Like</Link>
                                                            </li>
                                                            <li className="list-inline-item ml-1">
                                                                <Link to="#"><i className="mdi mdi-message-text align-middle mr-1"></i> Comment</Link>
                                                            </li>
                                                        </ul>
                                                    </Media>
                                                    <p className="float-sm-right font-size-12">22 Jan, 2020</p>
                                                </Media>
                                                <Media className="py-3">
                                                    <Media body>
                                                        <p className="text-muted mb-2">If several languages coalesce, the grammar of the resulting </p>
                                                        <h5 className="font-size-15 mb-3">Scott</h5>

                                                        <ul className="list-inline product-review-link mb-0">
                                                            <li className="list-inline-item">
                                                                <Link to="#"><i className="mdi mdi-thumb-up align-middle mr-1"></i> Like</Link>
                                                            </li>
                                                            <li className="list-inline-item ml-1">
                                                                <Link to="#"><i className="mdi mdi-message-text align-middle mr-1"></i> Comment</Link>
                                                            </li>
                                                        </ul>
                                                    </Media>
                                                    <p className="float-sm-right font-size-12">04 Jan, 2020</p>
                                                </Media>
                                            </div>
                                        </div> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md={4}>
                                                <Media>
                                                    <div className="avatar-sm mr-3">
                                                        <span className="avatar-title bg-light rounded-circle text-primary font-size-24">
                                                            <i className="ri-checkbox-circle-line"></i>
                                                        </span>
                                                    </div>
                                                    <Media body className="align-self-center overflow-hidden">
                                                        <h5>Free Shipping</h5>
                                                        <p className="text-muted mb-0">Sed ut perspiciatis unde</p>
                                                    </Media>
                                                </Media>
                                            </Col>
                                            <Col md={4}>
                                                <Media className="mt-4 mt-md-0">
                                                    <div className="avatar-sm mr-3">
                                                        <span className="avatar-title bg-light rounded-circle text-primary font-size-24">
                                                            <i className="ri-exchange-line"></i>
                                                        </span>
                                                    </div>
                                                    <Media body className="align-self-center overflow-hidden">
                                                        <h5>Easy Return</h5>
                                                        <p className="text-muted mb-0">Neque porro quisquam est</p>
                                                    </Media>
                                                </Media>
                                            </Col>
                                            <Col md={4}>
                                                <Media className="mt-4 mt-md-0">
                                                    <div className="avatar-sm mr-3">
                                                        <span className="avatar-title bg-light rounded-circle text-primary font-size-24">
                                                            <i className="ri-money-dollar-circle-line"></i>
                                                        </span>
                                                    </div>
                                                    <Media body className="align-self-center overflow-hidden">
                                                        <h5>Cash on Delivery</h5>
                                                        <p className="text-muted mb-0">Ut enim ad minima quis</p>
                                                    </Media>
                                                </Media>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                                    </Row> */}

            <Modal
              isOpen={this.state.modal_static}
              toggle={this.tog_static}
              backdrop="static"
              centered
              size="lg"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_static: false })}
              >
                <b>Edit Charger Params </b>
                (* Mandatory fields)
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={this.addCustomer} model={location}>
                  <Row>
                    <Col lg={12}>
                      <Alert
                        color="success"
                        isOpen={this.state.isAlertOpen}
                        toggle={() => this.setState({ isAlertOpen: false })}
                      >
                        Data Added Successfully...!
                      </Alert>
                      <FormGroup>
                        <Label htmlFor="name">Charger Id</Label>
                        <AvField
                          name="chargerId"
                          type="text"
                          className="form-control"
                          id="chargerId"
                          placeholder="Enter Charger Id"
                          value={this.state.chargerId}
                          required
                        ></AvField>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label htmlFor="number">Latitude*</Label>
                        <AvField
                          name="lat"
                          type="text"
                          className="form-control"
                          id="loclat"
                          placeholder="Enter Latitude"
                          value={location.lat}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={6}>
                      <FormGroup>
                        <Label htmlFor="email">Longitude*</Label>
                        <AvField
                          name="long"
                          type="text"
                          className="form-control"
                          id="loclong"
                          placeholder="Enter Longitude"
                          value={location.long}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label htmlFor="number">Price*</Label>
                        <AvField
                          name="lat"
                          type="text"
                          className="form-control"
                          id="loclat"
                          placeholder="Enter Latitude"
                          value="10"
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={6}>
                      <FormGroup>
                        <Label htmlFor="email">Time-zone*</Label>
                        <select
                          id="timezone"
                          name="timezone"
                          type="text"
                          className="form-control"
                        >
                          <option>Coordinated Universal Time (UTC)</option>
                          <option selected="selected">
                            Indian Standard Time (UTC + 5:30)
                          </option>
                          <option>US/Pacific Standard Time (UTC - 8)</option>
                          <option>US/Mountian Standard Time (UTC - 7)</option>
                          <option>US/Central Standard Time (UTC - 6)</option>
                          <option>US/Eastern Standard Time (UTC - 6)</option>
                          <option>Mideast/Riyadh87 (UTC + 3:07)</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      <FormGroup>
                        <Label htmlFor="metatitle">Location*</Label>
                        <select
                          id="location"
                          name="location"
                          type="text"
                          className="form-control"
                        >
                          <option>i-Pec India Pvt Ltd</option>
                          <option>iqlabs</option>
                          {/*<option > {<Link to="cms-locations">"...Add New Location"</Link>}</option>*/}
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg={6}>
                      <FormGroup>
                        <Label htmlFor="address">Address</Label>
                        <AvField
                          name="addr"
                          type="text"
                          className="form-control"
                          id="locaddr"
                          placeholder="Enter Address"
                          value={location.address}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <ModalFooter>
                    <Button
                      type="button"
                      color="light"
                      onClick={() => this.setState({ modal_static: false })}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" color="primary">
                      Update
                    </Button>
                  </ModalFooter>
                </AvForm>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedChargerId: state.ChargerManagement.selectedChargerId,
  user: state.Login.user,
});

const mapDispatchToProps = (dispatch) => ({
  chargerSelected: (chargerId) => dispatch(chargerSelected(chargerId)),
});

//connect(mapStateToProps, mapDispatchToProps)(ProductDetail)
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);

//export default ProductDetail;
