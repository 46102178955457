import React, { Component } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Collapse,
  CardHeader,
  Input,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { Link } from "react-router-dom";

// RangeSlider
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MiniWidgets from "../Dashboard/MiniWidgets";

//Import Product Images
import product1 from "../../assets/images/product/Picture1.jpg";
import product2 from "../../assets/images/product/Picture2.jpg";
import product3 from "../../assets/images/product/Picture1.jpg";
import product4 from "../../assets/images/product/img-4.png";
import product5 from "../../assets/images/product/img-5.png";
import product6 from "../../assets/images/product/img-6.png";

import ChargerFilter from "../../components/CommonForBoth/ChargerFilter";

import store from "../../store";
import { chargerSelected } from "../../store/actions";
import actions from "redux-form/lib/actions";

let chargerId_1 = "08ED8F1B5AE0";
//let chargerId_2 = "B401991B5AE0";
//let chargerId_3 = "944A4CEF49C0";
let chargerId_4 = "80C606144B8C";

/* let chargerId_1 = "6076A1ACCD98";
let chargerId_2 = "2C484CEF49C0";
let chargerId_3 = "20898795AB34"; */

//let chargerId_1 = "1CB705144B8C";
///let chargerId_2 = "2C484CEF49C0";
let chargerId_2 = "D8478F0C8598";
//let chargerId_3 = "3C1E06144B8C";
let chargerId_3 = "98D88F1B5AE0";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Charger Management System", link: "#" },
        { title: "Chargers", link: "#" },
      ],
      reports: [
        {
          icon: "ri-checkbox-circle-fill",
          title: "Active",
          value: "0",
          rate: "12.33%",
          desc: "From last Month",
          theme: "text-success",
        },
        {
          icon: "ri-close-circle-fill",
          title: "Faulted",
          value: "0",
          rate: "10.59%",
          desc: "From last Month",
          theme: "text-danger",
        },
        {
          icon: "ri-cloud-off-fill",
          title: "Offline",
          value: "0",
          rate: "32.4%",
          desc: "From previous period",
          theme: "text-danger",
        },
      ],
      electronic: true,
      fashion: false,
      baby: false,
      fitness: false,
      discount: true,
      size: true,
      rating: false,
      charger1: "Offline",
      charger2: "Offline",
      charger3: "Offline",
      charger4: "Offline",
      syncIntervalId: -1,
      chargers: [
        {
          id: chargerId_1,
          type: "AC",
          power: "7.4",
          connectors: "1",
          firmware: "1.20",
          status: "Offline",
          manufacturer: "i-Pec",
          img: product1,
          desc: "AC 7.4kW, Type2",
        },
        {
          id: chargerId_2,
          type: "AC",
          power: "3.3",
          connectors: "1",
          firmware: "1.10",
          status: "Offline",
          manufacturer: "i-Pec",
          img: product2,
          desc: "AC 3.3kW, IEC 60309",
        },
        {
          id: chargerId_3,
          type: "AC",
          power: "22",
          connectors: "1",
          firmware: "1.00",
          status: "Offline",
          manufacturer: "i-Pec",
          img: product3,
          desc: "AC 22kW, Type2",
        },
        {
          id: chargerId_4,
          type: "AC",
          power: "7.4",
          connectors: "1",
          firmware: "1.00",
          status: "Offline",
          manufacturer: "i-Pec",
          img: product3,
          desc: "AC 7.4kW, Type2",
        },
      ],
    };
  }

  setChargerStatus = (chargerId, status) => {
    if (!status || !chargerId) return;

    const modifiedList = this.state.chargers.map((charger) => {
      if (charger.id === chargerId) charger.status = status;

      return charger;
    });

    if (this.state.chargers === modifiedList) return;

    this.setState({ chargers: modifiedList });
  };

  async componentDidMount() {
    try {
      const charger1_status = await this.getChargerStatus(chargerId_1);
      const charger2_status = await this.getChargerStatus(chargerId_2);
      const charger3_status = await this.getChargerStatus(chargerId_3);
      const charger4_status = await this.getChargerStatus(chargerId_4);

      this.setChargerStatus(chargerId_1, charger1_status);
      this.setChargerStatus(chargerId_2, charger2_status);
      this.setChargerStatus(chargerId_3, charger3_status);
      this.setChargerStatus(chargerId_4, charger4_status);

      /*  console.log("Charger 1 Status", charger1_status);
             console.log("Charger 2 Status", charger2_status);
             console.log("Charger 3 Status", charger3_status);
  */
      if (charger2_status === null) charger2_status = "Offline";

      this.setState({
        charger1: charger1_status,
        charger2: charger2_status,
        charger3: charger3_status,
        charger4: charger4_status,
      });

      this.updateChargerStats();
    } catch (e) {
      console.log("Chargers Page Component Mount Error", e.toString());
    }

    const intervalId = setInterval(async () => {
      try {
        const charger1_status = await this.getChargerStatus(chargerId_1);
        const charger2_status = await this.getChargerStatus(chargerId_2);
        const charger3_status = await this.getChargerStatus(chargerId_3);
        const charger4_status = await this.getChargerStatus(chargerId_4);

        if (charger2_status === null) charger2_status = "Offline";
        /* console.log("Charger 1 Status", charger1_status);
                console.log("Charger 2 Status", charger2_status);
                console.log("Charger 3 Status", charger3_status); */

        if (
          charger1_status === this.state.charger1 &&
          charger2_status === this.state.charger2 &&
          charger3_status === this.state.charger3 &&
          charger4_status === this.state.charger4
        )
          return;

        /* console.log("Charger 1 Status", charger1_status);
                console.log("Charger 2 Status", charger2_status);
                console.log("Charger 3 Status", charger3_status); */

        /*  this.setState({
                    charger1: charger1_status,
                    charger2: charger2_status,
                    charger3: charger3_status
                }); */

        this.setChargerStatus(chargerId_1, charger1_status);
        this.setChargerStatus(chargerId_2, charger2_status);
        this.setChargerStatus(chargerId_3, charger3_status);
        this.setChargerStatus(chargerId_4, charger4_status);

        this.updateChargerStats();
      } catch (e) {
        console.log("Chargers Page Periodic Update Error", e.toString());
      }
    }, 6000);

    this.setState({ syncIntervalId: intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.syncIntervalId);
  }

  updateChargerStats = () => {
    let active_ch = 0;
    let inactive_ch = 0;
    let offline_ch = 0;

    console.log("Charger 1 Stats", this.state.charger1);
    console.log("Charger 2 Stats", this.state.charger2);
    console.log("Charger 3 Stats", this.state.charger3);

    if (!this.state.charger1) {
      //inactive_ch++;
      offline_ch++;
    } else if (this.state.charger1 === "Faulted") inactive_ch++;
    else if (this.state.charger1 === "Offline") {
      offline_ch++;
    } else active_ch++;

    if (!this.state.charger2) {
      //inactive_ch++;
      offline_ch++;
    }
    //Charger 2
    else if (this.state.charger2 === "Faulted") inactive_ch++;
    else if (this.state.charger2 === "Offline") {
      offline_ch++;
    } else active_ch++;

    if (!this.state.charger3) {
      // inactive_ch++;
      offline_ch++;
    }
    //Charger 3
    else if (this.state.charger3 === "Faulted") inactive_ch++;
    else if (this.state.charger3 === "Offline") {
      offline_ch++;
    } else active_ch++;

    if (!this.state.charger4) {
      // inactive_ch++;
      offline_ch++;
    }
    //Charger 3
    else if (this.state.charger4 === "Faulted") inactive_ch++;
    else if (this.state.charger4 === "Offline") {
      offline_ch++;
    } else active_ch++;

    let updated_reports = [
      {
        icon: "ri-checkbox-circle-fill",
        title: "Active",
        value: active_ch.toString(),
        rate: "12.33%",
        desc: "From last Month",
        theme: "text-success",
      },
      {
        icon: "ri-close-circle-fill",
        title: "Faulted",
        value: inactive_ch.toString(),
        rate: "10.59%",
        desc: "From last Month",
        theme: "text-danger",
      },
      {
        icon: "ri-cloud-off-fill",
        title: "Offline",
        value: offline_ch.toString(),
        rate: "32.4%",
        desc: "From previous period",
        theme: "text-gray",
      },
    ];

    if (JSON.stringify(this.state.reports) === JSON.stringify(updated_reports))
      return;

    this.setState({ reports: updated_reports });
  };

  getChargerStatus = async (chargerId) => {
    if (chargerId == null) return;

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      /*    body: JSON.stringify({
                   token: "8lQzN922rpRDaMbh3m5J",
                   id: "#"
               }) */
    };
    const response = await fetch(
      "https://backend.iqlabs.io/api/evChargers/statusLogs/recent?token=8lQzN922rpRDaMbh3m5J&id=" +
        chargerId,
      requestOptions
    );
    const responseJson = await response.json();
    //console.log("Fetch data Reponse", responseJson);
    const data = responseJson.data;
    if (data.length <= 0) return;

    //console.log("Fetch data Reponse", data.at(0));
    return data.at(0).status;
  };

  selectCharger = (chargerId) => {
    if (chargerId == null) return;

    store.dispatch(chargerSelected(chargerId));
  };

  renderChargerConnectivityStatus = (chargerStatus) => {
    if (!chargerStatus)
      return <i className="mdi mdi-checkbox-blank-circle text-danger"></i>;

    if (chargerStatus === "Offline")
      return <i className="mdi mdi-checkbox-blank-circle text-danger"></i>;

    return <i className="mdi mdi-checkbox-blank-circle text-success"></i>;
  };

  renderChargerStatus = (chargerStatus) => {
    if (chargerStatus == null) return "";

    if (chargerStatus === "Available")
      return <h5 className="mt-3 mb-0 text-success">{chargerStatus}</h5>;

    if (chargerStatus === "Preparing")
      return <h5 className="mt-3 mb-0 text-warning">{chargerStatus}</h5>;

    if (chargerStatus === "Charging")
      return (
        <h5 className="mt-3 mb-0 bg-success text-white rounded">
          {chargerStatus}
        </h5>
      );

    if (chargerStatus === "Finishing")
      return <h5 className="mt-3 mb-0 text-warning">{chargerStatus}</h5>;

    if (chargerStatus === "Faulted")
      return (
        <h5 className="mt-3 mb-0 bg-danger text-white rounded">
          {chargerStatus}
        </h5>
      );

    if (chargerStatus === "Offline")
      return <h5 className="mt-3 mb-0 text-danger">{chargerStatus}</h5>;

    return <h5 className="mt-3 mb-0 text-info">{chargerStatus}</h5>;
  };

  filterChargers = (filterParams) => {
    if (!filterParams) return;

    if (filterParams.length <= 0) return;

    let result = this.state.chargers.filter(
      (charger) =>
        this.isCriteriaPass(filterParams.manufacturer, charger.manufacturer) &&
        this.isCriteriaPass(filterParams.connectors, charger.connectors) &&
        this.isCriteriaPass(filterParams.rating, charger.power) &&
        this.isCriteriaPass(filterParams.Type, charger.type)
    );

    console.log("Filtered Chargers", result);

    this.setState({ data: result });
  };

  getFilteredResults = (results) => {
    console.log("Filtered Results", results);

    this.filterChargers(results);
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Chargers"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <MiniWidgets reports={this.state.reports} />
            </Row>

            <Row>
              <Col xl={3} lg={4}>
                <Card>
                  <CardHeader className="bg-transparent border-bottom">
                    <h5 className="mb-0">Filters</h5>
                  </CardHeader>

                  <CardBody>
                    {/*<h5 className="font-size-14 mb-3">Categories</h5>

                                        <div id="accordion" className="custom-accordion categories-accordion mb-3">
                                            <div className="categories-group-card">
                                                <Link to="#" onClick={() => this.setState({ electronic: !this.state.electronic, fashion: false, baby: false, fitness: false })} className={this.state.electronic ? "categories-group-list accordian-bg-products" : "categories-group-list"}>
                                                    <i className="mdi mdi-lastfm font-size-16 align-middle mr-2"></i> AC Chargers
                                                    <i className={this.state.electronic === true ? "mdi mdi-minus float-right accor-minus-icon" : "mdi mdi-plus float-right accor-plus-icon"}></i>
                                                </Link>

                                                <Collapse isOpen={this.state.electronic} id="collapseOne">
                                                    <div>
                                                        <ul className="list-unstyled categories-list mb-0">
                                                            <li><Link to="#"><i className="mdi mdi-circle-medium mr-1"></i> 3.3kW IEC 60309, Single-socket</Link></li>
                                                            <li><Link to="#"><i className="mdi mdi-circle-medium mr-1"></i> 3.3kW Plug-socket, Single-socket</Link></li>
                                                            <li><Link to="#"><i className="mdi mdi-circle-medium mr-1"></i> 7.7kW Type-2, Single-connector</Link></li>
                                                            <li><Link to="#"><i className="mdi mdi-circle-medium mr-1"></i> 14.4kW Type-2, Multi-connector</Link></li>
                                                            <li><Link to="#"><i className="mdi mdi-circle-medium mr-1"></i> 11kW Type-2, Single-connector</Link></li>
                                                            <li><Link to="#"><i className="mdi mdi-circle-medium mr-1"></i> 22kW Type-2, Single-connector</Link></li>
                                                        </ul>
                                                    </div>
                                                </Collapse>
                                            </div>

                                            <div className="categories-group-card">
                                                <Link to="#" className={this.state.fashion ? "categories-group-list accordian-bg-products" : "categories-group-list"} onClick={() => this.setState({ fashion: !this.state.fashion, electronic: false, baby: false, fitness: false })}>
                                                    <i className="mdi mdi-ray-start-end font-size-16 align-middle mr-2"></i> DC Chargers
                                                    <i className={this.state.fashion === true ? "mdi mdi-minus float-right accor-minus-icon" : "mdi mdi-plus float-right accor-plus-icon"}></i>
                                                </Link>
                                                <Collapse isOpen={this.state.fashion} id="collapseTwo">
                                                    <div>
                                                        <ul className="list-unstyled categories-list mb-0">
                                                            <li className="active"><Link to="#"><i className="mdi mdi-circle-medium mr-1"></i> 30kW CCS2, Single-gun</Link></li>
                                                            <li><Link to="#"><i className="mdi mdi-circle-medium mr-1"></i> 60kW CCS2, Double-gun</Link></li>
                                                            <li><Link to="#"><i className="mdi mdi-circle-medium mr-1"></i> 120kW CCS2, Double-gun</Link></li>
                                                        </ul>
                                                    </div>
                                                </Collapse>
                                            </div>

                                            
                                            <div className="categories-group-card">
                                                <Link to="#" onClick={() => this.setState({ fitness: !this.state.fitness, fashion: false, baby: false, electronic: false })} className={this.state.fitness ? "categories-group-list accordian-bg-products" : "categories-group-list"}>
                                                    <i className="mdi mdi-dumbbell font-size-16 align-middle mr-2"></i> Combo Chargers
                                                    <i className={this.state.fitness === true ? "mdi mdi-minus float-right accor-minus-icon" : "mdi mdi-plus float-right accor-plus-icon"}></i>
                                                </Link>
                                                <Collapse isOpen={this.state.fitness} id="collapseFour">
                                                    <div>
                                                        <ul className="list-unstyled categories-list mb-0">
                                                            <li><Link to="#"><i className="mdi mdi-circle-medium mr-1"></i> 82kW CCS2+ChaDEMO+Type2 AC, Triple-gun </Link></li>
                                                            <li><Link to="#"><i className="mdi mdi-circle-medium mr-1"></i> 142kW CCS2+ChaDEMO+Type2 AC, Triple-gun</Link></li>
                                                        </ul>
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </div>*/}

                    <ChargerFilter
                      filterChargers={this.filterChargers}
                      manufacturers={[{ name: "i-Pec" }]}
                      powerRatings={["3.3", "7.2", "14.4", "22", "30"]}
                      results={this.getFilteredResults}
                    />
                  </CardBody>

                  {/*<CardBody className="border-top">
                                        <div>
                                            <h5 className="font-size-14 mb-4">Price</h5>
                                            <br />
                                            <Nouislider range={{ min: 0, max: 600 }} tooltips={true} start={[100, 500]} connect />
                                        </div>
    </CardBody> */}

                  {/*<div className="custom-accordion">
                                        <CardBody className="border-top">
                                            <div>
                                                <h5 className="font-size-14 mb-0"><Link to="#" className="text-dark d-block" onClick={() => this.setState({ discount: !this.state.discount })} >Discount <i className={this.state.discount === true ? "mdi mdi-minus float-right accor-minus-icon" : "mdi mdi-plus float-right accor-plus-icon"}></i></Link></h5>

                                                <Collapse isOpen={this.state.discount} id="collapseExample1">

                                                    <div className="mt-4">
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productdiscountRadio6" name="productdiscountRadio1" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productdiscountRadio6">50% or more</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productdiscountRadio5" name="productdiscountRadio1" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productdiscountRadio5">40% or more</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productdiscountRadio4" name="productdiscountRadio1" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productdiscountRadio4">30% or more</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productdiscountRadio3" name="productdiscountRadio1" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productdiscountRadio3">20% or more</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productdiscountRadio2" name="productdiscountRadio1" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productdiscountRadio2">10% or more</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productdiscountRadio1" name="productdiscountRadio1" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productdiscountRadio1">Less than 10%</Label>
                                                        </div>
                                                    </div>

                                                </Collapse>
                                            </div>
                                        </CardBody>

                                        <CardBody className="border-top">
                                            <div>
                                                <h5 className="font-size-14 mb-0"><Link to="#" className="text-dark d-block" onClick={() => this.setState({ size: !this.state.size })}>Size <i className={this.state.size === true ? "mdi mdi-minus float-right accor-minus-icon" : "mdi mdi-plus float-right accor-plus-icon"}></i></Link></h5>

                                                <Collapse isOpen={this.state.size} id="collapseExample2">

                                                    <div className="mt-4">
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productsizeRadio1" name="productsizeRadio" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productsizeRadio1">X-Large</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productsizeRadio2" name="productsizeRadio" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productsizeRadio2">Large</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productsizeRadio3" name="productsizeRadio" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productsizeRadio3">Medium</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productsizeRadio4" name="productsizeRadio" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productsizeRadio4">Small</Label>
                                                        </div>
                                                    </div>

                                                </Collapse>
                                            </div>
                                        </CardBody>
                                        <CardBody className="border-top">
                                            <div>
                                                <h5 className="font-size-14 mb-0"><Link to="#" className="collapsed text-dark d-block" onClick={() => this.setState({ rating: !this.state.rating })}>Customer Rating <i className={this.state.rating === true ? "mdi mdi-minus float-right accor-minus-icon" : "mdi mdi-plus float-right accor-plus-icon"}></i></Link></h5>

                                                <Collapse isOpen={this.state.rating} id="collapseExample3">

                                                    <div className="mt-4">
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productratingRadio1" name="productratingRadio1" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productratingRadio1">4 <i className="mdi mdi-star text-warning"></i>  & Above</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productratingRadio2" name="productratingRadio1" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productratingRadio2">3 <i className="mdi mdi-star text-warning"></i>  & Above</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productratingRadio3" name="productratingRadio1" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productratingRadio3">2 <i className="mdi mdi-star text-warning"></i>  & Above</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productratingRadio4" name="productratingRadio1" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productratingRadio4">1 <i className="mdi mdi-star text-warning"></i></Label>
                                                        </div>
                                                    </div>

                                                </Collapse>
                                            </div>
                                        </CardBody>
</div> */}
                </Card>
              </Col>
              <Col lg={9}>
                <Card>
                  <CardBody>
                    <div>
                      <Row>
                        <Col md={6}>
                          <div>
                            <h5>Chargers & Status</h5>
                            {/*<Breadcrumb listClassName="p-0 bg-transparent mb-2">
                                                            <BreadcrumbItem ><Link to="#">Fashion</Link></BreadcrumbItem>
                                                            <BreadcrumbItem ><Link to="#">Clothing</Link></BreadcrumbItem >
                                                            <BreadcrumbItem active>T-shirts</BreadcrumbItem >
</Breadcrumb>*/}
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="form-inline float-md-right">
                            <div className="search-box ml-2">
                              <div className="position-relative">
                                <Input
                                  type="text"
                                  className="form-control rounded"
                                  placeholder="Search..."
                                />
                                <i className="mdi mdi-magnify search-icon"></i>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <ul className="list-inline my-3 ecommerce-sortby-list">
                        <li className="list-inline-item">
                          <span className="font-weight-medium font-family-secondary">
                            Sort by:
                          </span>
                        </li>
                        <li className="list-inline-item active ml-1">
                          <Link to="#">All</Link>
                        </li>
                        <li className="list-inline-item ml-1">
                          <Link to="#">Online</Link>
                        </li>
                        <li className="list-inline-item ml-1">
                          <Link to="#">Offline</Link>
                        </li>
                      </ul>

                      <Row className="no-gutters">
                        {/*<Col xl={4} sm={6}>
                                                    <div className="product-box">
                                                        <Link to="cms-charger-detail" className="text-dark" onClick={() => { this.selectCharger(chargerId_1) }}>
                                                            <div className="product-img">

                                                                <div className="product-like">
                                                                    <Link to='#'>
                                                                        {this.renderChargerConnectivityStatus(this.state.charger1)}
                                                                    </Link>
                                                                </div>
                                                                <img src={product2} style={{ height: 200 }} alt="" className="img-fluid mx-auto d-block" />
                                                            </div>

                                                            <div className="text-center">

                                                                <p className="font-size-13 mb-1 text-primary">Id: {chargerId_1}</p>
                                                                <h5 className="font-size-15">AC 3.3kW, IEC 60309</h5>

                                                                {this.renderChargerStatus(this.state.charger1)}

                                                            </div>
                                                        </Link>
                                                    </div>
</Col>*/}{" "}
                        {this.state.chargers.map((charger, index) => (
                          <Col
                            xl={4}
                            sm={6}
                            key={"Chargers_" + index.toString()}
                          >
                            <div className="product-box">
                              <Link
                                to="cms-charger-detail"
                                className="text-dark"
                                onClick={() => {
                                  this.selectCharger(charger.id);
                                }}
                              >
                                <div className="product-img">
                                  {/*<div className="product-ribbon badge badge-primary">
                                                                        - 25 %
        </div> */}
                                  <div className="product-like">
                                    <Link to="#">
                                      {this.renderChargerConnectivityStatus(
                                        charger.status
                                      )}
                                    </Link>
                                  </div>
                                  <img
                                    src={charger.img}
                                    style={{ height: 200 }}
                                    alt=""
                                    className="img-fluid mx-auto d-block"
                                  />
                                </div>

                                <div className="text-center">
                                  <p className="font-size-13 mb-1 text-primary">
                                    Id: {charger.id}
                                  </p>
                                  <h5 className="font-size-15">
                                    {charger.desc}
                                  </h5>

                                  {this.renderChargerStatus(charger.status)}
                                </div>
                              </Link>
                            </div>
                          </Col>
                        ))}
                        {/*<Col xl={4} sm={6}>
                                                    <div className="product-box">
                                                        <Link to="cms-charger-detail" className="text-dark" onClick={() => { this.selectCharger(chargerId_3) }}>
                                                            <div className="product-img">
                                                                <div className="product-like">
                                                                    <Link to='#'>
                                                                        {this.renderChargerConnectivityStatus(this.state.charger3)}
                                                                    </Link>
                                                                </div>
                                                                <img src={product2} style={{ height: 200 }} alt="" className="img-fluid mx-auto d-block" />
                                                            </div>

                                                            <div className="text-center">
                                                                <p className="font-size-13 mb-1 text-primary">Id: {chargerId_3}</p>
                                                                <h5 className="font-size-15">AC 3.3kW, IEC 60309</h5>

                                                                {this.renderChargerStatus(this.state.charger3)}

                                                            </div>
                                                        </Link>
                                                    </div>
                                                </Col>*/}
                        {/*<Col xl={4} sm={6}>
                                                    {<div className="product-box">
                                                        <div className="product-img">
                                                            <div className="product-like">
                                                                <Link to="#">
                                                                    <i className="mdi mdi-heart-outline"></i>
                                                                </Link>
                                                            </div>
                                                            <img src={product4} alt="" className="img-fluid mx-auto d-block" />
                                                        </div>

                                                        <div className="text-center">
                                                            <p className="font-size-12 mb-1">Gray color, Hoodie</p>
                                                            <h5 className="font-size-15"><Link to="#" className="text-dark">Hoodie (Green)</Link></h5>

                                                            <h5 className="mt-3 mb-0"><span className="text-muted mr-2"><del>$290</del></span>$275</h5>
                                                        </div>
                                                    </div>}
                                                </Col>

                                                <Col xl={4} sm={6}>
                                                    <div className="product-box">
                                                        <div className="product-img">
                                                            <div className="product-like">
                                                                <Link to="#">
                                                                    <i className="mdi mdi-heart text-danger"></i>
                                                                </Link>
                                                            </div>
                                                            <img src={product5} alt="" className="img-fluid mx-auto d-block" />
                                                        </div>

                                                        <div className="text-center">
                                                            <p className="font-size-12 mb-1">Blue color, T-shirt</p>
                                                            <h5 className="font-size-15"><Link to="#" className="text-dark">Full sleeve T-shirt</Link></h5>

                                                            <h5 className="mt-3 mb-0">$242</h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xl={4} sm={6}>
                                                    <div className="product-box">
                                                        <div className="product-img">
                                                            <div className="product-ribbon badge badge-primary">
                                                                - 22 %
                                                            </div>
                                                            <div className="product-like">
                                                                <Link to="#">
                                                                    <i className="mdi mdi-heart-outline"></i>
                                                                </Link>
                                                            </div>
                                                            <img src={product6} alt="" className="img-fluid mx-auto d-block" />

                                                        </div>

                                                        <div className="text-center">
                                                            <p className="font-size-12 mb-1">Black color, T-shirt</p>
                                                            <h5 className="font-size-15"><Link to="#" className="text-dark">Half sleeve T-shirt </Link></h5>

                                                            <h5 className="mt-3 mb-0"><span className="text-muted mr-2"><del>$240</del></span>$225</h5>
                                                        </div>
                                                    </div>
</Col>*/}
                      </Row>

                      <Row className="mt-4">
                        <Col sm={6}>
                          <div>
                            <p className="mb-sm-0 mt-2">
                              Page <span className="font-weight-bold">1</span>{" "}
                              Of <span className="font-weight-bold">1</span>
                            </p>
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="float-sm-right">
                            <Pagination className="pagination-rounded mb-sm-0">
                              <PaginationItem disabled>
                                <PaginationLink href="#">
                                  <i className="mdi mdi-chevron-left"></i>
                                </PaginationLink>
                              </PaginationItem>
                              <PaginationItem active>
                                <PaginationLink href="#">1</PaginationLink>
                              </PaginationItem>
                              {/*<PaginationItem>
                                                                <PaginationLink href="#" >2</PaginationLink>
                                                            </PaginationItem>
                                                            <PaginationItem>
                                                                <PaginationLink href="#" >3</PaginationLink>
                                                            </PaginationItem>
                                                            <PaginationItem>
                                                                <PaginationLink hrefo="#" >4</PaginationLink>
                                                            </PaginationItem>
                                                            <PaginationItem>
                                                                <PaginationLink href="#" >5</PaginationLink>
                                            </PaginationItem> */}
                              <PaginationItem>
                                <PaginationLink href="#">
                                  <i className="mdi mdi-chevron-right"></i>
                                </PaginationLink>
                              </PaginationItem>
                            </Pagination>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Products;
